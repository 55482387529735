//
// email.scss
//

.page-aside-left {
    width: 340px;
    float: left;
    padding: 0 0px 20px 10px;
    position: relative;
    border-radius: 10px;

    &:before {
        content: '';
        background: $body-bg;
        width: 5px;
        position: absolute;
        right: -15px;
        height: 100%;
        bottom: -1.5rem;
    }
}

.page-aside-right {
    margin: -1.5rem 0 -1.5rem 340px;
    border-left: 5px solid $body-bg;
    padding: 0 0 1.5rem 25px;
}

.email-list {
    display: block;
    padding-left: 0;

    > li {
        position: relative;
        display: block;
        height: 55px;
        line-height: 48px;
        cursor: default;
        transition-duration: 0.3s;

        a {
            color: #{map-get($grays, '700')};

            &:hover {
                color: #{map-get($grays, '800')};
            }
        }

        .col-mail {
            float: left;
            position: relative;
        }

        .email-sender-info {
            width: 320px;

            .star-toggle,
            .checkbox-wrapper-mail {
                display: block;
                float: left;
            }

            .checkbox-wrapper-mail {
                margin: 15px 10px 0 20px;
                cursor: pointer;
                height: 20px;
                width: 20px;
            }

            .star-toggle {
                color: #{map-get($grays, '500')};
                margin-left: 10px;
                font-size: 18px;
            }

            .email-title {
                position: absolute;
                top: 0;
                left: 100px;
                right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0;
                line-height: 57px;
            }
        }

        .email-content {
            position: absolute;
            top: 0;
            left: 320px;
            right: 0;
            bottom: 0;
            .email-subject,
            .email-date {
                position: absolute;
                top: 0;
                line-height: 57px;
            }
            .email-subject {
                left: 0;
                right: 110px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .email-date {
                right: 0;
                width: 100px;
                text-align: right;
                padding-left: 10px;
            }
        }

        &.active,
        &.mail-selected {
            background: #{map-get($grays, '100')};
            transition-duration: 0.05s;
        }

        &.unread {
            a {
                font-weight: $font-weight-semibold;
                color: darken($dark, 5%);
            }
        }

        .email-action-icons {
            opacity: 0;
            line-height: 57px;
            ul {
                display: flex;
                position: absolute;
                transition: all 0.5s;
                right: -180px;

                li {
                    margin: 0 10px;
                }

                .email-action-icons-item {
                    font-size: 20px;
                    transition: all 0.5s;

                    &:hover {
                        color: $danger;
                    }
                }
            }
        }

        &:hover {
            background: #{map-get($grays, '100')};
            transition-duration: 0.05s;

            .email-action-icons {
                opacity: 1;

                ul {
                    transition: all 0.5s;
                    right: 10px;
                }
            }

            .email-content {
                .email-date {
                    opacity: 0;
                }

                .email-subject {
                    right: 180px;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.email-menu-list {
    a {
        color: #{map-get($grays, '700')};
        padding: 12px 5px;
        display: block;
        font-size: 15px;

        &:hover {
            color: #{map-get($grays, '800')};
        }

        .badge {
            margin-top: 3px;
        }
    }
}

.labels-list {
    a {
        padding: 7px 5px;
    }
}

.write-mdg-box {
    .CodeMirror {
        height: 150px;
    }
}

@media (max-width: 648px) {
    .page-aside-left {
        width: 100%;
        float: none;
        padding: 0 10px 20px 10px;

        &:before {
            width: 0;
        }
    }
    .page-aside-right {
        margin-left: 0;
        border: 0;
        padding-left: 0;
    }
    .email-list {
        li {
            .email-sender-info {
                .checkbox-wrapper-mail {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .page-aside-right {
        > .btn-group {
            margin-bottom: 10px;
        }
    }
    .email-list {
        li {
            .email-sender-info {
                width: 150px;
                .email-title {
                    left: 80px;
                }
            }
            .email-content {
                display: none;
            }
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .page-aside-left {
        &::before {
            background: var(--#{$variable-prefix}body-bg);
        }
    }

    .page-aside-right {
        border-left: 5px solid var(--#{$variable-prefix}body-bg);
    }
    .email-list {
        > li {
            a {
                color: #{map-get($dark-grays, '700')};

                &:hover {
                    color: #{map-get($dark-grays, '800')};
                }
            }
            .email-sender-info {
                .star-toggle {
                    color: #{map-get($dark-grays, '500')};
                }
            }
            &.active,
            &.mail-selected {
                background: #{map-get($dark-grays, '100')};
            }
            &:hover {
                background: #{map-get($dark-grays, '100')};
            }
        }
    }
    .email-menu-list {
        a {
            color: #{map-get($dark-grays, '700')};
            &:hover {
                color: #{map-get($dark-grays, '800')};
            }
        }
    }

    .email-menu-list > .email-menu-item {
        padding: 14px 30px;
        cursor: pointer;

        /* border-bottom: 1px solid #353f4e !important; */
    }

    .email-menu-item-active {
        color: #dee2e6;
    }

    .email-menu-item-inactive {
        color: rgb(170, 184, 197);
    }

    .email-menu-list > .email-menu-item:hover {
        background-color: rgba(127, 127, 150, 0.3);
    }

    .left-side-menu-container {
        background-color: #162b3c;
        border: 1px solid #353f4e;
        border-radius: 20px;
        position: fixed;
        width: 328px;
    }

    .right-side-message-container {
        background-color: #162b3c;
        border: 1px solid #353f4e;
        border-radius: 20px;
        padding: 20px 15px;
    }

    .right-side-message-container li {
        background-color: rgba(127, 127, 150, 0);
    }

    .right-side-message-container li:hover {
        background-color: rgba(127, 127, 150, 0);
    }

    ul.email-list {
        margin-bottom: 0px !important;
    }

    ul.email-list > li {
        border-bottom: 2px solid rgb(127, 127, 150, 0.2);
    }

    ul.email-list > li:hover {
        background-color: rgb(127, 127, 150, 0.3);
    }

    div.list-inline-item {
        background-color: rgb(127, 127, 150, 0);
        font-size: 17px;
    }

    .dropdown-menu {
        min-width: 0px;
    }

    .email-action-icons-go {
        display: block;
        position: absolute;
        right: 10px !important;
    }

    .dropdown-item {
        padding: 4px 19px;
    }
}

body[data-layout-color='light'] {
    .email-menu-list > .email-menu-item {
        padding: 14px 30px;
        cursor: pointer;
        /* border-bottom: 1px solid #353f4e !important; */
    }

    .email-menu-list > .email-menu-item:hover {
        background-color: rgba(127, 127, 150, 0.1);
    }

    .left-side-menu-container {
        border: 1px solid #a5b0c2;
        border-radius: 20px;
        position: fixed;
        width: 328px;
        background-color: #a5b0c226;
    }

    .right-side-message-container {
        border: 1px solid #a5b0c2;
        border-radius: 20px;
        padding: 20px 15px;
        background-color: #a5b0c226;
    }

    .right-side-message-container li {
    }

    ul.email-list {
        margin-bottom: 0px !important;
    }

    ul.email-list > li {
        border-bottom: 2px solid rgb(127, 127, 150, 0.2);
    }

    ul.email-list > li:hover {
        background-color: rgb(127, 127, 150, 0.1);
    }

    div.list-inline-item {
        background-color: rgb(127, 127, 150, 0);
        font-size: 17px;
    }

    .dropdown-menu {
        min-width: 0px;
    }

    .email-action-icons-go {
        display: block;
        position: absolute;
        right: 10px !important;
    }

    .dropdown-item {
        padding: 4px 19px;
    }

    .email-menu-item-active {
        color: #727cf5;
    }

    .email-menu-item-inactive {
        color: #6c757d;
    }

    .unread {
        color: #6c757d;
    }

    .email-list > li.unread a {
        color: #6c757d;
        font-weight: bold;
    }

    .email-list > li .email-content .email-subject {
        color: #6c757d;
        font-weight: normal;
    }
}
