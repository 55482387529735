// Utilities

@import 'utilities/background';
@import 'utilities/text';

// border style
.border-dashed {
    border-style: dashed !important;
}

// Dark mode
body[data-layout-color='dark'] {
    @each $color, $value in $dark-theme-colors {
        .border-#{$color} {
            border-color: $value !important;
        }
    }
}

body[data-layout-color='light'] {
    .serverDatenTitel {
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        margin: 0px;
        padding: 0.8rem;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;
    }

    .serverDatenContainer {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

body[data-layout-color='dark'] {
    .serverDatenTitel {
        color: #b2bac1;
        border-top: 1px solid #464f5b;
        border-left: 1px solid #464f5b;
        border-right: 1px solid #464f5b;
        margin: 0px;
        padding: 0.8rem;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        text-align: center;
    }

    .serverDatenContainer {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}
