//
// accordions.scss
//

.custom-accordion {
    .card {
        box-shadow: none;
    }

    .card-header {
        background-color: #{map-get($grays, '100')};
    }

    .card-body {
        border: #{($border-width) solid map-get($grays, '100')};
    }

    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: '\F142';
                }
            }
        }
    }
}

.custom-accordion-title {
    color: $custom-accordion-title-color;
    position: relative;

    &:hover {
        color: #{lighten(map-get($grays, '900'), 7.5%)};
    }
}

.accordion {
    > .card {
        > .card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .custom-accordion {
        .card-header {
            background-color: #{map-get($dark-grays, '100')};
        }
        .card-body {
            border: #{($border-width) solid map-get($dark-grays, '100')};
        }
    }
    .custom-accordion-title {
        &:hover {
            color: #{lighten(map-get($dark-grays, '400'), 7.5%)};
        }
    }
}

body[data-layout-color='dark'] {
    .price-list {
        display: flex;
    }

    .price-list > div {
        flex-grow: 3;
        margin: 0 10px;
        border: black solid 1px;
        padding: 20px;
    }

    button.aboKuendigen {
        background: #727cf5;
        border-radius: var(--radius);
        color: white;
        border: 0;
        padding: 8px 10px;
        margin-top: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        display: block;
    }

    .StripeElement + .text-center + button {
        background: #727cf5;
        border-radius: var(--radius);
        color: white;
        border: 0;
        padding: 8px 10px;
        margin-top: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        display: block;
    }

    button:active {
        transform: translateY(0px) scale(0.98);
        filter: brightness(0.9);
    }
    button:disabled {
        opacity: 0.5;
        cursor: none;
    }

    /* a {
        color: var(--accent-color);
    } */

    .status-button-outline a {
        color: white !important;
    }

    small {
        font-size: 0.6em;
    }

    #payment-form {
        border: #f6f9fc solid 1px;
        border-radius: var(--radius);
        padding: 20px;
        margin: 20px 0;
        box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
    }

    #messages {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
        background-color: #0a253c;
        color: #00d924;
        padding: 20px;
        margin: 20px 0;
        border-radius: var(--radius);
        font-size: 0.7em;
    }

    /*
CSS to style Stripe credit card fields on PMPro checkout page
Add this CSS to your theme's stylesheet.
*/
    .ElementsApp input {
        color: #e3eaef !important;
    }

    input.InputContainer {
        color: #e3eaef !important;
    }

    input.InputElement .is-complete {
        color: #e3eaef !important;
    }
    /* Base Stripe element styling*/
    .StripeElement {
        background-color: #e3eaef;
        padding: 10px !important;

        max-width: 100%;
        display: block;
        width: 100%;
        padding: 0.45rem 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid #4a525d;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input.stripeFormInputs {
        background-color: #e3eaef;
        padding: 5px !important;

        max-width: 100%;
        display: block;
        width: 100%;
        font-weight: 400;
        background-clip: padding-box;
        border: 1px solid #4a525d;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input#name {
        background-color: #e3eaef;
        width: 50%;
        padding: 5px !important;
        display: block;
        font-weight: 400;
        background-clip: padding-box;
        border: 1px solid #4a525d;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    /* Set a red bottom border to field when empty */
    .StripeElement--empty {
        border-bottom-color: #b22222;
    }

    /* Set a green bottom border to field when in focus */
    .StripeElement--focus {
        border-bottom-color: #008000;
    }

    /* Set styling when form field data is invalid */
    .StripeElement--invalid {
        background-color: #f2ff0034;
        border-color: #b22222;
    }

    /* Set styling when form field data is completed and valid */
    .StripeElement--complete {
        background-color: #e3eaef;
    }

    /* Set credit card number input field width */
    form.pmpro_form #pmpro_payment_information_fields div#AccountNumber {
        max-width: 270px;
    }

    /* Set expiry date input field width */
    form.pmpro_form #pmpro_payment_information_fields div#Expiry {
        max-width: 110px;
    }

    /* Set CVC/CVV input field width */
    form.pmpro_form #pmpro_payment_information_fields div#CVV {
        max-width: 110px;
    }

    .Toastify__toast-icon {
        max-width: 20px;
    }

    .pricingCheckOutModal > .modal-dialog {
        width: 1100px;
        max-width: 1100px;
    }

    .pricingCheckOutModal .modal-body {
        padding: 0px;
        border-radius: 12px;
    }

    .pricingCheckOutModal .card {
        border-radius: 12px;
        margin-bottom: 0px;
    }

    .pricingCheckOutModal .card-body {
        padding-bottom: 0px;
        margin-bottom: 20px;
        border-radius: 12px;
    }

    .buttonModalCloseCheckout {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .buttonModalCloseCheckout:active {
        transform: translateY(0px) scale(1);
    }

    .subscripeSummaryTitle {
        background-color: #00000077;
        margin: 0px;
        padding: 0px;
        text-align: center;
    }

    .subscribeSummaryTableDataLeft {
        background-color: #2e4152;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .subscribeSummaryTableDataLeft > h4 {
        color: #d8e2ef !important;
    }

    .subscribeSummaryTableDataRight {
        background-color: #2e4152;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .subscribeSummaryTableDataRight > h4 {
        color: #d8e2ef !important;
    }

    .subscribeSummaryRow {
        background-color: #12283d;
        margin: 0px;
        padding: 0px;
    }

    .messageSummaryTitle {
        background-color: #00000077;
        margin: 0px;
        padding: 0px;
        text-align: center;
    }

    .messageDoubleSummaryRow {
        background-color: #142c44;
        margin: 0px;
        padding: 0px;
    }

    .messageDoubleTableData {
        background-color: #2b4257;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
    }

    .messageSummaryTableDataLeft {
        background-color: #1e3244;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .messageSummaryTableDataLeft > h4 {
        color: #d8e2ef !important;
    }

    .messageSummaryTableDataRight {
        background-color: #1e3244;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .messageSummaryTableDataRight > h4 {
        color: #d8e2ef !important;
    }

    .messageSummaryRow {
        background-color: #12283d;
        margin: 0px;
        padding: 0px;
    }

    .checkOutBodytext {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .checkOutBodytext > .card-body {
        padding-top: 10px;
    }

    .service-plan-title {
        color: #d8e2ef;
    }

    .subscribeSummaryPricing {
        padding: 2px;
        background-color: #12283d;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        /*       box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
    }
    .subscribeSummaryPricing > h4 {
        color: #d8e2ef !important;
    }

    input#name::placeholder {
        color: #757575 !important;
    }

    input#name:focus {
        outline: none !important;
        border: 1px solid #a6afbc;
    }

    input.stripeFormInputs::placeholder {
        color: #757575 !important;
    }

    input.stripeFormInputs:focus {
        outline: none !important;
        border: 1px solid #a6afbc;
    }

    .checkOutBodytext > .card-body {
        padding-bottom: 0px;
    }

    .pricingCheckOutModal .card-body {
        margin-bottom: 0px;
        background-color: #182f44;
    }

    .checkOutBodytext {
        background-color: #fafbfe;
    }

    .creditCardCheckOut {
        margin-bottom: 1.5rem;
    }

    a.agb-select:hover {
        color: #727cf5;
    }

    .service-plan-PLAN {
        color: white;
    }

    .pricing-overview-container {
        border-radius: 30px;
        box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, 0.9) !important;
        border: 1px solid rgba(99, 102, 241, 0.3);
    }

    li.pricing-feature-list {
        font-size: 1.1rem;
        padding: 2px;
    }

    li.pricing-feature-list-amount {
        font-size: 1.1rem;
        padding: 2px;
        font-weight: bold;
    }

    .pricing-headline {
        font-weight: normal;
    }

    h1.card-pricing-plan-name {
        color: #ced4ca;
    }

    .card-pricing-plan-name {
        font-size: 2.1rem;
        margin-top: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    h2.card-pricing-price {
        font-size: 2.9rem;
        padding-top: 27px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        color: #ced4ca;
    }

    .pricing-duration {
        font-size: 1.05rem;
    }

    .getStartedNowButton {
        border-radius: 10px;
    }

    .getStartedNowButton:hover {
        background-color: #6366f1;
    }

    .dropzoneUp {
        border: 2px dashed var(--ct-input-border-color);
        background: #2e4152; /* var(--ct-input-bg); */
        border-radius: 6px;
        cursor: pointer;
        min-height: 150px;
        padding: 20px;
        width: 100%;
    }

    .dropzoneUp .dz-messageUp {
        text-align: center;
        margin: 0.8rem 0;
    }

    .dropzoneUpCSV {
        border: 2px dashed var(--ct-input-border-color);
        background: #2e4152; /* var(--ct-input-bg); */
        border-radius: 6px;
        cursor: pointer;
        min-height: 20px;
        padding: 20px;
        width: 100%;
    }

    label.payment-title-input-labels {
        display: block;
    }

    span.reductionPercentage {
        color: rgb(9, 204, 15) !important;
        letter-spacing: 0px;
        margin-left: 7px;
        position: absolute;
    }

    span.reductionPercentageSummary {
        color: rgb(9, 204, 15) !important;
        letter-spacing: 0px;
        margin-left: 7px;
        position: absolute;
        font-size: 16px;
    }

    span.reductionPercentageAccount {
        letter-spacing: 0px;
        position: absolute;
        font-size: 12px;
    }

    .urlIsEditingFormControl {
        border: 1px solid rgb(230, 230, 0);
        background-color: rgb(230, 230, 0, 0.3);
        border-radius: 7px;
    }

    .urlIsErrordFormControl {
        border: 1px solid rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.3);
        border-radius: 7px;
    }

    .urlIsSuccedFormControl {
        border: 1px solid rgb(0, 203, 0);
        background-color: rgb(0, 203, 0, 0.1) !important;
        border-radius: 7px;
    }

    .urlIsEditingPreview {
        border: 1px dashed rgb(230, 230, 0);
        background-color: rgb(230, 230, 0, 0.1);
        border-radius: 7px;
    }

    .urlIsErrordPreview {
        border: 1px dashed rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.1);
        border-radius: 7px;
    }

    .shopDeactivatedMessage {
        border: 1px solid rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.1);
        padding: 7px;
        border-radius: 7px;
    }

    .shopDeactivatedMessage:hover {
        cursor: pointer;
        background-color: rgb(203, 0, 0, 0.3);
    }

    .urlIsSuccedPreview {
        color: #e3eaef;
        border: 1px dashed rgb(0, 203, 0);
        background-color: rgb(0, 203, 0, 0.1);
        border-radius: 7px;
    }

    .urlIsEditingPreview:hover {
        background-color: rgb(230, 230, 0, 0.3);
    }

    .urlIsErrordPreview:hover {
        background-color: rgb(203, 0, 0, 0.3);
    }

    .urlIsSuccedPreview:hover {
        background-color: rgb(0, 203, 0, 0.3);
    }

    .urlIsEditingStandard {
        /* border: 1px solid rgb(230, 230, 0);
        background-color: rgb(230, 230, 0, 0.1); */
        border-radius: 7px;
    }

    .urlIsErrordStandard {
        color: rgb(203, 0, 0);
        /* border: 1px solid rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.1); */
        border-radius: 7px;
    }

    .urlIsSuccedStandard {
        /* border: 1px solid rgb(0, 203, 0);
        background-color: rgba(0, 119, 0, 0.1); */
        border-radius: 7px;
    }

    .chatUserCard {
        border-radius: 20px;
        border: 1px solid #353f4e !important;
        display: block;
        max-height: 100%;
    }

    .chatContainer {
        border-radius: 10px;
        border: 1px solid #353f4e !important;
    }

    .ultraChatUserContainer > .card {
        border-radius: 20px;
        border: 1px solid #353f4e !important;
    }

    .hoverAvatar {
        border-radius: 10px;
    }

    .chatUsersOverflowContainer {
        padding-bottom: 3px;
        height: 100%;
        width: auto;
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
        scrollbar-width: none !important;

        &::-webkit-scrollbar {
            width: 0.5em;
            height: 0.5em;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 3px;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }

    input::placeholder {
        color: #adb5bd;
    }

    .user-box-profile {
        background: linear-gradient(180deg, #291c62 0%, #002c7a 100%);
        box-shadow: 0px 0px 2px white;
        color: white;
    }

    .landUniIconContainerStripe {
        color: white !important;
    }

    .landUniIconContainerStripe > div {
        background-color: white !important;
        color: black !important;
        border: 0px;
        border-radius: 0.25rem;
    }

    .landUniIconContainerPublicProfile__control {
        border: 1px solid #4b535e;
        color: white !important;
    }

    .landUniIconContainerPublicProfile > div {
        background-color: #2e4152 !important;
        color: #e3eaef !important;
    }

    .landUniIconContainerPublicProfilePlaceholder > div {
        background-color: #2e4152 !important;
    }

    .landUniIconContainerPublicProfilePlaceholder .react-select__placeholder {
        color: #e3eaef !important;
    }

    .landUniIconContainerPublicProfilePlaceholder input::placeholder {
        color: white !important;
    }

    .react-select__option--is-focused,
    .react-select__option--is-selected {
        background-color: #4b535e !important;
    }
}

body[data-layout-color='light'] {
    .price-list {
        display: flex;
    }

    .price-list > div {
        flex-grow: 3;
        margin: 0 10px;
        border: black solid 1px;
        padding: 20px;
    }

    button.aboKuendigen {
        background: #727cf5;
        border-radius: var(--radius);
        color: white;
        border: 0;
        padding: 8px 10px;
        margin-top: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        display: block;
    }

    .StripeElement + .text-center + button {
        background: #727cf5;
        border-radius: var(--radius);
        color: white;
        border: 0;
        padding: 8px 10px;
        margin-top: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        display: block;
    }

    button:active {
        transform: translateY(0px) scale(0.98);
        filter: brightness(0.9);
    }
    button:disabled {
        opacity: 0.5;
        cursor: none;
    }

    a {
        color: var(--accent-color);
    }

    small {
        font-size: 0.6em;
    }

    #payment-form {
        border: #f6f9fc solid 1px;
        border-radius: var(--radius);
        padding: 20px;
        margin: 20px 0;
        box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
    }

    #messages {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
        background-color: #0a253c;
        color: #00d924;
        padding: 20px;
        margin: 20px 0;
        border-radius: var(--radius);
        font-size: 0.7em;
    }

    /*
CSS to style Stripe credit card fields on PMPro checkout page
Add this CSS to your theme's stylesheet.
*/
    .ElementsApp input {
        color: #e3eaef !important;
    }

    input.InputContainer {
        color: #e3eaef !important;
    }

    input.InputElement .is-complete {
        color: #e3eaef !important;
    }
    /* Base Stripe element styling*/
    .StripeElement {
        background-color: #e3eaef;
        padding: 10px !important;

        max-width: 100%;
        display: block;
        width: 100%;
        padding: 0.45rem 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid #4a525d;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input.stripeFormInputs {
        padding: 5px !important;
        background-color: #fafbfe;
        border: 1px solid #a6afbc;
        max-width: 100%;
        display: block;
        width: 100%;
        font-weight: 400;
        background-clip: padding-box;

        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input#name {
        background-color: #fafbfe;
        border: 1px solid #a6afbc !important;
        padding: 5px !important;
        width: 50%;
        display: block;
        font-weight: 400;
        background-clip: padding-box;
        border: 1px solid #4a525d;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    /* Set a red bottom border to field when empty */
    .StripeElement--empty {
        border-color: #d41010;
        background-color: #fafbfe;
        border: 1px solid #a6afbc;
    }

    /* Set a green bottom border to field when in focus */
    .StripeElement--focus {
        border-bottom-color: #0fbb0f;
        background-color: #fafbfe;
        border: 1px solid #a6afbc;
    }

    /* Set styling when form field data is invalid */
    .StripeElement--invalid {
        background-color: #fafbfe;
        border: 1px solid #a6afbc;
        border-color: #d41010;
    }

    /* Set styling when form field data is completed and valid */
    .StripeElement--complete {
        border: 1px solid #a6afbc;
    }

    /* Set credit card number input field width */
    form.pmpro_form #pmpro_payment_information_fields div#AccountNumber {
        max-width: 270px;
    }

    /* Set expiry date input field width */
    form.pmpro_form #pmpro_payment_information_fields div#Expiry {
        max-width: 110px;
    }

    /* Set CVC/CVV input field width */
    form.pmpro_form #pmpro_payment_information_fields div#CVV {
        max-width: 110px;
    }

    .Toastify__toast-icon {
        max-width: 20px;
    }

    .pricingCheckOutModal > .modal-dialog {
        width: 1100px;
        max-width: 1100px;
    }

    .pricingCheckOutModal .modal-body {
        padding: 0px;
    }

    .pricingCheckOutModal .card {
        border-radius: 12px;
        margin-bottom: 0px;
    }

    .pricingCheckOutModal .card-body {
        padding-bottom: 0px;
        margin-bottom: 20px;
    }

    .buttonModalCloseCheckout {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .buttonModalCloseCheckout:active {
        transform: translateY(0px) scale(1);
    }

    .subscripeSummaryTitle {
        background-color: #727cf5aa;
        margin: 0px;
        padding: 0px;
        text-align: center;
        padding: 0px !important;
    }

    .subscribeSummaryTableDataLeft {
        background-color: #fafbfe;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .subscribeSummaryTableDataLeft > h4 {
        color: #344050df !important;
    }

    .subscribeSummaryTableDataRight {
        background-color: #fafbfe;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .subscribeSummaryTableDataRight > h4 {
        color: #344050df !important;
    }

    .subscribeSummaryRow {
        background-color: #9aaab5;
        margin: 0px;
        padding: 0px;
        color: #344050df;
    }

    .messageDoubleSummaryRow {
        background-color: #ced8e2;
        margin: 0px;
        padding: 0px;
        color: #344050df;
    }

    .messageDoubleTableData {
        background-color: #ced8e2;
        color: #344050df;

        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
    }

    .messageSummaryTableDataLeft {
        background-color: #fafbfe;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .messageSummaryTableDataLeft > h4 {
        color: #344050df !important;
    }

    .messageSummaryTableDataRight {
        background-color: #fafbfe;
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
        /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

    .messageSummaryTableDataRight > h4 {
        color: #344050df !important;
    }

    .messageSummaryRow {
        background-color: #8296a5;
        margin: 0px;
        padding: 0px;
    }

    .checkOutBodytext {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .checkOutBodytext > .card-body {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .subscribeSummaryPricing {
        background-color: #e3eaef;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        /*  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
        padding: 2px;
    }
    .subscribeSummaryPricing > h4 {
        color: #344050df !important;
    }

    input#name::placeholder {
        color: #757575 !important;
    }

    input#name:focus {
        outline: none !important;
        border: 1px solid #a6afbc;
    }

    input.stripeFormInputs::placeholder {
        color: #757575 !important;
    }

    input.stripeFormInputs:focus {
        outline: none !important;
        border: 1px solid #a6afbc;
    }

    .service-plan-title {
        color: #344050df;
    }

    .payment-title-input-labels {
        color: #344050df;
    }

    .checkOutBodytext > .card-body {
        padding-bottom: 0px;
    }

    .pricingCheckOutModal .card-body {
        margin-bottom: 0px;
        background-color: #f9f9fb;
        border-radius: 12px;
    }

    .checkOutBodytext {
        background-color: #fafbfe;
    }

    .creditCardCheckOut {
        margin-bottom: 1.5rem;
    }

    a.agb-select:hover {
        color: #727cf5;
    }

    .service-plan-PLAN {
        color: #344050;
    }

    .pricing-overview-container {
        border-radius: 30px;
        box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(99, 102, 241, 0.9) !important;
        border: 1px solid rgba(99, 102, 241, 0.3);
    }

    li.pricing-feature-list {
        color: #565973;
        font-size: 1.1rem;
        padding: 2px;
    }

    li.pricing-feature-list-amount {
        color: #565973;
        font-size: 1.1rem;
        padding: 2px;
        font-weight: bold;
    }

    .pricing-headline {
        font-weight: normal;
    }

    h1.card-pricing-plan-name {
        color: #565973;
    }

    .card-pricing-plan-name {
        font-size: 2.1rem;
        margin-top: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    h2.card-pricing-price {
        font-size: 2.9rem;
        padding-top: 27px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        color: #565973;
    }

    .pricing-duration {
        font-size: 1.05rem;
    }

    .getStartedNowButton {
        border-radius: 10px;
    }

    .getStartedNowButton:hover {
        background-color: #6366f1;
    }

    .dropzoneUp {
        border: 2px dashed #4b535e;
        background: #ebeff3; /* var(--ct-input-bg); */
        border-radius: 6px;
        cursor: pointer;
        min-height: 150px;
        padding: 20px;
        width: 100%;
    }

    .dropzoneUpCSV {
        border: 2px dashed #4b535e;
        background: #ebeff3; /* var(--ct-input-bg); */
        border-radius: 6px;
        cursor: pointer;
        min-height: 20px;
        padding: 20px;
        width: 100%;
    }

    .dropzoneUp .dz-messageUp {
        text-align: center;
        margin: 0.8rem 0;
    }

    label.payment-title-input-labels {
        display: block;
    }

    span.reductionPercentage {
        color: rgb(7, 234, 14) !important;
        letter-spacing: 0px;
        margin-left: 7px;
        position: absolute;
    }

    span.reductionPercentageSummary {
        color: #00d26a !important;
        letter-spacing: 0px;
        margin-left: 7px;
        position: absolute;
        font-size: 16px;
    }

    .urlIsEditingFormControl {
        color: rgb(102, 107, 1) !important;
        border: 1px solid rgb(230, 230, 0);
        background-color: rgb(230, 230, 0, 0.3) !important;
        border-radius: 7px;
    }

    .urlIsErrordFormControl {
        color: rgb(203, 0, 0);
        border: 1px solid rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.3) !important;
        border-radius: 7px;
    }

    .urlIsSuccedFormControl {
        color: #005c00 !important;
        border: 1px solid rgb(0, 203, 0);
        background-color: rgb(0, 203, 0, 0.3) !important;
        border-radius: 7px;
    }

    .urlIsEditingPreview {
        color: #393c3f !important;
        border: 1px dashed rgb(230, 230, 0);
        background-color: rgb(230, 230, 0, 0.1);
        border-radius: 7px;
    }

    .urlIsErrordPreview {
        color: #393c3f !important;
        border: 1px dashed rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.1);
        border-radius: 7px;
    }

    .shopDeactivatedMessage {
        color: #393c3f !important;
        border: 1px solid rgb(203, 0, 0);
        background-color: rgb(203, 0, 0, 0.1);
        padding: 7px;
        border-radius: 7px;
    }

    .urlIsSuccedPreview {
        color: #393c3f !important;
        border: 1px dashed rgb(0, 203, 0);
        background-color: rgb(0, 203, 0, 0.1);
        border-radius: 7px;
    }

    .urlIsEditingPreview:hover {
        background-color: rgb(230, 230, 0, 0.3);
    }

    .urlIsErrordPreview:hover {
        background-color: rgb(203, 0, 0, 0.3);
    }

    .shopDeactivatedMessage:hover {
        cursor: pointer;
        background-color: rgb(203, 0, 0, 0.3);
    }

    .urlIsSuccedPreview:hover {
        background-color: rgb(0, 203, 0, 0.3);
    }

    .urlIsEditingStandard {
        /* color: rgb(102, 107, 1) !important; */
        /* border: 1px solid rgb(230, 230, 0); */
        /* background-color: rgb(230, 230, 0, 0.1); */
        border-radius: 7px;
    }

    .urlIsErrordStandard {
        /* color: rgb(203, 0, 0); */
        /* border: 1px solid rgb(203, 0, 0); */
        /* background-color: rgb(203, 0, 0, 0.1); */
        border-radius: 7px;
    }

    .urlIsSuccedStandard {
        /* color: #005c00 !important; */
        /* border: 1px solid rgb(0, 203, 0); */
        /* background-color: rgb(0, 203, 0, 0.1); */
        border-radius: 7px;
    }

    .chatUserCard {
        border-radius: 20px;
        border: 1px solid #cad1dc !important;
        display: block;
        max-height: 100%;
    }

    .chatContainer {
        border-radius: 10px;
        border: 1px solid #cad1dc !important;
    }

    .ultraChatUserContainer > .card {
        border-radius: 20px;
        border: 1px solid #cad1dc !important;
    }

    .hoverAvatar {
        border-radius: 10px;
    }

    .chatUsersOverflowContainer {
        padding-bottom: 3px;
        height: 100%;
        width: auto;
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
        scrollbar-width: none !important;

        &::-webkit-scrollbar {
            width: 0.5em;
            height: 0.5em;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 3px;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }

    .user-box-profile {
        background: linear-gradient(180deg, #5941c6 0%, #005bff 100%);
        box-shadow: 0px 0px 2px white;
        color: white;
    }
}

.overflowChatMessage {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 180px !important;
}

.overflowUsername {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 200px !important;
    display: inline-block;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.overflowUsernameChatArea {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 400px !important;
    display: inline-block;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
