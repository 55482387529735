//
// tabs.scss
//

.nav-tabs,
.nav-pills {
    > li {
        > a {
            color: #{map-get($grays, '700')};
            font-weight: $font-weight-semibold;
        }
    }
}

.nav-pills {
    > a {
        color: #{map-get($grays, '700')};
        font-weight: $font-weight-semibold;
    }
}

.bg-nav-pills {
    background-color: rgba(0, 0, 0, 0); // $nav-pills-bg;
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
    border-bottom: 2px solid #{rgba(map-get($grays, '600'), 0.2)};

    .nav-item {
        margin-bottom: -1px;
    }

    li {
        a {
            border: 0;
            padding: 0.625rem 1.25rem;
        }
        a.active {
            border-bottom: 2px solid $primary;
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .nav-tabs,
    .nav-pills {
        > li {
            > a {
                color: #{map-get($dark-grays, '700')};
            }
        }
    }
    .nav-pills {
        > a {
            color: #{map-get($dark-grays, '700')};
        }
    }

    .nav-tabs.nav-bordered {
        border-bottom: 2px solid #{rgba(map-get($dark-grays, '600'), 0.2)};
    }
}

body[data-layout-color='light'] {
    .nav-pills {
        .nav-item {
            margin: 3px;
        }
        .nav-link {
            background: #e0e9f1;
            border: 0;
            @include border-radius($nav-pills-border-radius);
        }

        .nav-link:hover {
            background: #cfdeec;
        }

        .nav-link.active,
        .show > .nav-link {
            color: $nav-pills-link-active-color;
            border-bottom: 1px solid rgb(5, 5, 5);
            background: linear-gradient(45deg, rgba(89, 65, 198, 1) 0%, rgba(0, 91, 255, 1) 100%);
            /* @include gradient-bg($nav-pills-link-active-bg); */
        }
    }
}

body[data-layout-color='dark'] {
    .nav-pills {
        .nav-item {
            margin: 3px;
        }

        .nav-link {
            background: #2e4152;

            @include border-radius($nav-pills-border-radius);
        }

        .nav-link:hover {
            background: #344c62;
        }

        .nav-link.active,
        .show > .nav-link {
            color: $nav-pills-link-active-color;
            box-shadow: 0px 0px 1px white;
            border-bottom: 1px solid white;
            background: linear-gradient(45deg, rgba(41, 28, 98, 1) 0%, rgba(0, 44, 122, 1) 100%);
            /* @include gradient-bg($nav-pills-link-active-bg); */
        }
    }
}
