.circleNumber {
    background: #e3e3e3;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #6e6e6e;
    display: inline-block;
    font-weight: bold;
    line-height: 30px;
    margin-left: 10px;
    text-align: center;
    min-width: 30px;
    max-width: 60px;
}

.Temporär {
    background-color: #727cf5;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.Aktiv {
    background-color: #0acf97;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.Passiv {
    background-color: #ffbc00;
    padding: 0.45rem 0.9rem;
    color: black;
    border-radius: 0.15rem;
}

.Verkauft {
    background-color: #39afd1;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.Geliefert {
    background-color: #fa5c7c;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.Archiv {
    background-color: #6c757d;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.Gelöscht {
    background-color: #f82a54;
    padding: 0.45rem 0.9rem;
    color: white;
    border-radius: 0.15rem;
}

.showTableZF {
    display: block;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.showTableZFAdmin {
    display: block;
    width: 325px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table > :not(caption) > * > * {
    padding: 0.6rem 0.6rem;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--ct-table-accent-bg);
}

.previewImgHover {
    border: 1px solid #2c90dd4d;
}

.previewImgHover:hover {
    border: 1px dashed #727cf5;
}

/* .th > div > button:hover {
    background-color: #bfddf5;
}
 */
.notification-list > a > .nav-link:hover {
    background-color: rgba(0, 0, 0, 0);
}

.notification-list > button:hover {
    background-color: rgba(0, 0, 0, 0);
}

select {
    display: inline-block;
    height: 30px;
    width: 130px;
    outline: none;
    color: #74646e;
    border: 1px solid #c8bfc4;
    border-radius: 4px;
    box-shadow: inset 1px 1px 2px #ddd8dc;
    background: #fff;
    text-align: center;
}

.Toastify__toast-icon {
    max-width: 20px;
}

.btn {
    border-radius: 5px;
}

.verkaufsplattform > input {
    margin-top: 1px;
}
